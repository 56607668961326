import lionVideo1 from '../img/videos/lion1.mp4';
import lionVideo2 from '../img/videos/lion2.mp4';
import lionVideo3 from '../img/videos/lion3.mp4';
import crocVideo1 from '../img/videos/croc1.mp4';
import crocVideo2 from '../img/videos/croc2.mp4';
import crocVideo3 from '../img/videos/croc3.mp4';
import elephantVideo1 from '../img/videos/elephant1.mp4';
import elephantVideo2 from '../img/videos/elephant2.mp4';
import elephantVideo3 from '../img/videos/elephant3.mp4';
import dragonVideo1 from '../img/videos/dragon1.mp4';
import dragonVideo2 from '../img/videos/dragon2.mp4';
import dragonVideo3 from '../img/videos/dragon3.mp4';
import monkeyVideo1 from '../img/videos/monkey1.mp4';
import monkeyVideo2 from '../img/videos/monkey2.mp4';
import monkeyVideo3 from '../img/videos/monkey3.mp4';
import linoImg from '../img/images/lion.jpg';
import crocImg from '../img/images/croc.jpg';
import elephantImg from '../img/images/elephant.jpg';
import dragonImg from '../img/images/dragon.jpg';
import monkeyImg from '../img/images/monkey.jpg';
const duration = 60;
export const videoDataBase = [
  {
    id: 1,
    name: 'Lion',
    url: [lionVideo1, lionVideo2, lionVideo3],
    img: linoImg,
    type: 'animals',
    duration: duration,
    multiplier: 1,
    bonus: 10,
    score: 30,
    stats: {
      weight: ['Weight', '190 kg'],
      speed: ['Top Speed', '80 km/h'],
      length: ['Length', '2 m'],
      country: ['Continent', 'Africa'],
      funFact: 'Lions are the only cats that live in groups called prides.',
    },
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    questionType: {
      1: `Phylum`,
      2: `Class`,
      3: `Order`,
    },
    answer: {
      1: 'Chordata',
      2: 'Mammalia',
      3: 'Carnivora',
    },
  },
  {
    id: 2,
    name: 'Elephant',
    url: [elephantVideo1, elephantVideo2, elephantVideo3],
    img: elephantImg,
    type: 'animals',
    duration: duration,
    multiplier: 1,
    bonus: 10,
    score: 30,
    stats: {
      weight: ['Weight', '6000 kg'],
      speed: ['Top Speed', '40 km/h'],
      length: ['Length', '6 m'],
      country: ['Continent', 'Africa'],
      funFact: 'Elephants are the largest land animals on Earth.',
    },
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    questionType: {
      1: `Phylum`,
      2: `Class`,
      3: `Order`,
    },
    answer: {
      1: 'Chordata',
      2: 'Mammalia',
      3: 'Proboscidea',
    },
  },
  {
    id: 3,
    name: 'Komodo dragon',
    url: [dragonVideo1, dragonVideo2, dragonVideo3],
    img: dragonImg,
    type: 'animals',
    duration: duration,
    multiplier: 1,
    bonus: 10,
    score: 30,
    stats: {
      weight: ['Weight', '70 kg'],
      speed: ['Top Speed', '20 km/h'],
      length: ['Length', '3 m'],
      country: ['Continent', 'Asia'],
      funFact: 'Komodo dragons are the largest lizards in the world.',
    },
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    questionType: {
      1: `Phylum`,
      2: `Class`,
      3: `Order`,
    },
    answer: {
      1: 'Chordata',
      2: 'Reptilia',
      3: 'Squamata',
    },
  },
  {
    id: 4,
    name: 'Monkey',
    url: [monkeyVideo1, monkeyVideo2, monkeyVideo3],
    img: monkeyImg,
    type: 'animals',
    duration: duration,
    multiplier: 1,
    bonus: 10,
    score: 30,
    stats: {
      weight: ['Weight', '10 kg'],
      speed: ['Top Speed', '40 km/h'],
      length: ['Length', '1 m'],
      country: ['Continent', 'Africa'],
      funFact: 'Monkeys are known for their intelligence and agility.',
    },
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    questionType: {
      1: `Phylum`,
      2: `Class`,
      3: `Order`,
    },
    answer: {
      1: 'Chordata',
      2: 'Mammalia',
      3: 'Primates',
    },
  },
  {
    id: 5,
    name: 'Crocodile',
    url: [crocVideo1, crocVideo2, crocVideo3],
    img: crocImg,
    type: 'animals',
    duration: duration,
    multiplier: 1,
    bonus: 10,
    score: 30,
    stats: {
      weight: ['Weight', '1000 kg'],
      speed: ['Top Speed', '30 km/h'],
      length: ['Length', '5 m'],
      country: ['Continent', 'Africa'],
      funFact: 'Crocodiles have been around for more than 200 million years.',
    },
    question: {
      1: 'What is the Phylum of this animal?',
      2: 'What is the Class of this animal?',
      3: 'What is the Order of this animal?',
    },
    questionType: {
      1: `Phylum`,
      2: `Class`,
      3: `Order`,
    },
    answer: {
      1: 'Chordata',
      2: 'Reptilia',
      3: 'Crocodylia',
    },
  },
];
