import React, { useState, useEffect, useRef, useCallback } from 'react';
import VideoComponent from '../components/video.jsx';
import Questions from '../components/questions.jsx';
import Countdown from '../components/countDown.jsx';
import { Box } from '@chakra-ui/react';
import EndRound from '../components/endRound.jsx';
import { useAuth } from './../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import apiService from '../localServer/apiService.jsx';
import { updateDailyChallenge } from '../components/cookiesService.jsx';

//Initialize the gameData object with the question, answer, correct, and timeAnswered properties.
function initializeGameData(video) {
  const videoData = video;
  const initialGameData = {};

  Object.keys(videoData.question).forEach(key => {
    initialGameData[key] = {
      question: videoData.question[key],
      answer: videoData.answer[key],
      questionType: videoData.questionType[key],
      correct: false,
      trys: 0,
      complete: false,
      userAnswer: '',
    };
  });
  return initialGameData;
}
let gameData = {};
let dailyChallenge = [1, 2, 3, 4, 5]; //in future make call to API to get daily challenge

export default function Game({ windowSize }) {
  const inputRef = useRef(null);
  const countDownRef = useRef(null);
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();
  const [video, setVideo] = useState();
  const [mainTime, setMainTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [endRound, setEndRound] = useState(false);
  const [maxPauses] = useState(0); //set max pauses to 0 for now upadate later
  const [numQuestions, setNumQuestions] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState('1');
  const [restart, setRestart] = useState(0); //for css code on countDown.jsx
  const [position, setPosition] = useState('relative'); // Focus the input when the game starts
  const [blurTimeout, setBlurTimeout] = useState();

  // ####################### focusInput ####################################

  const focusInput = useCallback(() => {
    clearTimeout(blurTimeout);
    setPosition('absolute');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [blurTimeout]);

  // ####################### handleBlur ####################################

  const handleBlur = () => {
    const timeout = setTimeout(() => {
      setPosition('relative');
    }, 500); // Delay of 500ms
    setBlurTimeout(timeout); // Store the timeout in state
  };

  // ####################### loadVideo ####################################

  function loadVideo(id) {
    const fetchVideoData = async () => {
      try {
        // Call the apiService with the 'videos' endpoint and the round
        const videoData = await apiService('videos', 'GET', { id });
        if (videoData) {
          setVideo(videoData);
          setMainTime(videoData.duration);
          gameData = initializeGameData(videoData);
          setNumQuestions(Object.keys(videoData.question).length);
        }
      } catch (err) {
        console.error('Error fetching video data:', err);
      }
    };

    fetchVideoData();
  }

  useEffect(() => {
    // Call loadVideo only once when the component mounts
    if (
      !user.dailyChallenge.complete &&
      user.dailyChallenge.round >= dailyChallenge.length
    ) {
      updateDailyChallenge({ round: null, complete: true });
      refreshUser();
    }
    loadVideo(dailyChallenge[user.dailyChallenge.round]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures it runs only once on mount

  // Main timerTimer for the game
  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setMainTime(oldValue => {
          if (oldValue <= 1) {
            clearInterval(intervalId);
            setActiveQuestion(numQuestions); // Last question when time runs out
            gameData[numQuestions].complete = true;
            gameData[numQuestions].correct = false;
            setPosition('relative');
            setEndRound(true);
            return 0;
          } else {
            const questionTime = video.duration / numQuestions;
            const timeIntoVideo = video.duration - oldValue + 1;
            let curQuest = Math.floor(timeIntoVideo / questionTime) + 1;
            if (curQuest > numQuestions) {
              curQuest = numQuestions;
            }
            if (activeQuestion < `${curQuest}`) {
              gameData[activeQuestion].complete = true;
              gameData[activeQuestion].correct = false;
              setActiveQuestion(`${curQuest}`);
            }

            return oldValue - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isPlaying, mainTime, video, numQuestions, activeQuestion]);

  useEffect(() => {
    if (endRound) {
      // If endRound is true, pause the game by setting isPlaying to false
      setIsPlaying(false);
    }
  }, [endRound]);

  // Restart the game by setting all the states to their initial values and clearing the gameData object
  const restartGames = () => {
    // Clear gameData object
    gameData = {};
    if (
      !user.dailyChallenge.complete &&
      user.dailyChallenge.round >= dailyChallenge.length
    ) {
      updateDailyChallenge({ round: null, complete: true });
      refreshUser();

      navigate('/dailyChallenge');
    }
    // ############## get new video from database ###################
    loadVideo(dailyChallenge[user.dailyChallenge.round]);
    setIsPlaying(false);
    setRestart(prev => prev + 1);
    setActiveQuestion('1');
    setEndRound(false);
  };
  return (
    <Box
      pos={[position, position, `relative`]}
      top={0}
      maxH={[windowSize.windowHeight, windowSize.windowHeight - 100, 'auto']}
    >
      {video ? (
        <>
          <Countdown
            countDownRef={countDownRef}
            mainTimer={true}
            mainTime={mainTime}
            time={video.duration}
            isPlaying={isPlaying}
            restart={restart}
            gameData={gameData}
            numQuestions={numQuestions}
          />
          <VideoComponent
            url={video.url}
            maxPauses={maxPauses}
            setIsPlaying={setIsPlaying}
            restart={restart}
            isPlaying={isPlaying}
            focusInput={focusInput}
            user={user}
            rounds={dailyChallenge.length}
            activeQuestion={activeQuestion}
          />
          <Questions
            type={video.type}
            isPlaying={isPlaying}
            gameData={gameData}
            activeQuestion={activeQuestion}
            setActiveQuestion={value => setActiveQuestion(value)}
            numQuestions={numQuestions}
            setEndRound={value => setEndRound(value)}
            inputRef={inputRef}
            focusInput={focusInput}
            user={user}
            rounds={dailyChallenge.length}
            handleBlur={handleBlur}
          />
          <EndRound
            isOpen={endRound}
            onClose={() => restartGames()}
            gameData={gameData}
            stats={video.stats}
            scorePerAns={video.score}
            multiplier={video.multiplier}
            numQuestions={numQuestions}
            bonus={video.bonus}
            user={user}
            rounds={dailyChallenge.length}
            image={video.img}
            name={video.name}
            windowSize={windowSize}
          />
        </>
      ) : (
        <Box>Loading...</Box>
      )}
    </Box>
  );
}
