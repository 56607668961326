import React, { useState, useEffect } from 'react';
import { VStack, HStack, Text, Spacer } from '@chakra-ui/react';
import ChatBox from './../components/chatBox.jsx'; // Ensure this path is correct
import { useAuth } from './../context/AuthContext'; // Ensure this path is correct
import { chatMessages } from '../localServer/chatMessages.jsx'; // Ensure this path is correct

export default function DailyChallenge({ windowSize }) {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [scores] = useState(user?.dailyChallenge?.score || []);
  // const scores = [
  //   [100, 100],
  //   [100, 100],
  //   [100, 100],
  //   [100, 100],
  //   [100, 100],
  // ];

  useEffect(() => {
    // Simulating fetch from a local file or API
    setMessages(chatMessages);
  }, []);

  const sendMessage = async newMessageText => {
    // Simulate sending message by adding it to the current state
    const newMessage = {
      id: messages.length + 1,
      username: user.user,
      message: newMessageText,
      timestamp: new Date().toISOString(),
    };
    setMessages([...messages, newMessage]);
  };

  const totalScore = scores.reduce((acc, [score]) => acc + score, 0);
  // const totalOutOf = scores.reduce((acc, [, outOf]) => acc + outOf, 0);

  return (
    <VStack spacing={1}>
      {/* Score Summary */}
      <HStack
        w={`100%`}
        spacing={0}
        align="center"
        fontSize={[`10px`, `13px`, `20px`]}
        minH={`62px`}
        maxH={`62px`}
      >
        <Spacer />
        <Text pr={2}>Scores:</Text>
        {scores.map((score, index) => (
          <VStack
            pt={`2px`}
            key={index}
            align="center"
            border={`1px solid gray`}
            bg={index % 2 === 0 ? `whiteAlpha.200` : `whiteAlpha.400`}
            spacing={0}
            minW={[`35px`, `45px`, `80px`]}
          >
            <Text>R{index + 1}</Text>
            <Text>{score[0]}</Text>
          </VStack>
        ))}
        <VStack align="center" pl={2} spacing={0}>
          <Text fontWeight="bold">Total:</Text>
          <Text fontWeight="bold">{totalScore}</Text>
        </VStack>
        <Spacer />
      </HStack>

      {/* Chat Feature */}
      <ChatBox
        messages={messages}
        onSendMessage={sendMessage}
        windowSize={windowSize}
      />
    </VStack>
  );
}
