import { userData } from '../localServer/userDataBase';

function generateUniqueId() {
  const tokenLength = 16;
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';

  for (let i = 0; i < tokenLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    token += characters.charAt(randomIndex);
  }

  return token;
}

export default async function EmailRegister(email, password, name) {
  try {
    // const type = 'register'
    // local server to test
    // const response = await fetch('http://localhost:3001/login', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ email, password , type}),
    // });
    // // Check if the response is not OK (status 2xx)
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }
    // const data = await response.json();

    //#########################################################

    const existingUser = userData.find(user => user.email === email);

    if (existingUser) {
      return {
        res: false,
        message: 'Email already registered! Please log in instead',
      };
    }

    // If user is not found, register the user
    const idToken = generateUniqueId();
    const newUser = {
      id: userData.length + 1,
      email,
      password,
      idToken,
      user: name,
      scores: {
        all: 0,
        day: 0,
        week: 0,
        month: 0,
        year: 0,
      }, // You may want to prompt the user for their name during registration
    };

    userData.push(newUser);

    return {
      res: true,
      user: newUser.user,
      idToken,
      message: 'Registration successful',
    };
  } catch (error) {
    console.error('Error during login:', error);
    return { res: false, message: error.message || 'Unknown error' };
  }
}
