import avatar1 from '../img/avatar/avatar1.png';
export const userData = [
  {
    id: 1,
    email: 'test@example.com',
    password: '123456$m',
    idToken: '1234567890',
    user: 'Test Man',
    avatar: avatar1,
    friends: [2, 3, 4, 6, 8, 9, 15, 17, 18, 19],
    dailyChallenge: {
      complete: false,
      score: [],
      round: 0,
    },
    scores: {
      all: 58000,
      day: 9300,
      week: 11200,
      month: 93500,
      year: 115000,
    },
  },
];
