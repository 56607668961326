import avatar1 from '../img/avatar/avatar1.png';
import avatar2 from '../img/avatar/avatar2.png';
import avatar3 from '../img/avatar/avatar3.png';
import avatar4 from '../img/avatar/avatar4.png';
import avatar5 from '../img/avatar/avatar5.png';
import avatar6 from '../img/avatar/avatar6.png';
import avatar7 from '../img/avatar/avatar7.png';
import avatar8 from '../img/avatar/avatar8.png';
import avatar9 from '../img/avatar/avatar9.png';
import avatar10 from '../img/avatar/avatar10.png';
import avatar11 from '../img/avatar/avatar11.png';
import avatar12 from '../img/avatar/avatar12.png';
import avatar13 from '../img/avatar/avatar13.png';
import avatar14 from '../img/avatar/avatar14.png';
import avatar15 from '../img/avatar/avatar15.png';
import avatar16 from '../img/avatar/avatar16.png';
import avatar17 from '../img/avatar/avatar17.png';
import avatar18 from '../img/avatar/avatar18.png';
import avatar19 from '../img/avatar/avatar19.png';
import avatar20 from '../img/avatar/avatar20.png';

export const leaderBoardData = [
  {
    id: 1,
    name: 'Test Man',
    avatar: avatar1,
    friends: [2, 3, 4, 6, 8, 9, 15, 17, 18, 19],
    scores: {
      all: 58000,
      day: 9300,
      week: 11200,
      month: 93500,
      year: 115000,
    },
  },
  {
    id: 2,
    name: 'Jane Smith',
    avatar: avatar2,
    friends: [1, 3],
    scores: {
      all: 13000,
      day: 250,
      week: 1100,
      month: 3000,
      year: 13000,
    },
  },
  {
    id: 3,
    name: 'Bob Johnson',
    avatar: avatar3,
    friends: [1, 2, 4],
    scores: {
      all: 14000,
      day: 280,
      week: 1150,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 4,
    name: 'Alice Williams',
    avatar: avatar4,
    friends: [1, 3],
    scores: {
      all: 12000,
      day: 200,
      week: 1000,
      month: 2800,
      year: 12000,
    },
  },
  {
    id: 5,
    name: 'Charlie Brown',
    avatar: avatar5,
    friends: [1, 6, 7, 8],
    scores: {
      all: 18000,
      day: 320,
      week: 1400,
      month: 4000,
      year: 18000,
    },
  },
  {
    id: 6,
    name: 'Diana Miller',
    avatar: avatar6,
    friends: [5, 7],
    scores: {
      all: 11000,
      day: 180,
      week: 900,
      month: 2500,
      year: 11000,
    },
  },
  {
    id: 7,
    name: 'Ethan Davis',
    avatar: avatar7,
    friends: [5, 6, 8],
    scores: {
      all: 16000,
      day: 300,
      week: 1300,
      month: 3600,
      year: 16000,
    },
  },
  {
    id: 8,
    name: 'Fiona Moore',
    avatar: avatar8,
    friends: [1, 5, 7],
    scores: {
      all: 10000,
      day: 150,
      week: 800,
      month: 2200,
      year: 10000,
    },
  },
  {
    id: 9,
    name: 'George Wilson',
    avatar: avatar9,
    friends: [10, 11, 12],
    scores: {
      all: 20000,
      day: 340,
      week: 1500,
      month: 4200,
      year: 20000,
    },
  },
  {
    id: 10,
    name: 'Hannah Lee',
    avatar: avatar10,
    friends: [9, 11],
    scores: {
      all: 12000,
      day: 200,
      week: 1000,
      month: 2800,
      year: 12000,
    },
  },
  {
    id: 11,
    name: 'Ian Taylor',
    avatar: avatar11,
    friends: [9, 10, 12],
    scores: {
      all: 14000,
      day: 240,
      week: 1100,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 12,
    name: 'Jessica Hall',
    avatar: avatar12,
    friends: [9, 11],
    scores: {
      all: 11000,
      day: 180,
      week: 900,
      month: 2500,
      year: 11000,
    },
  },
  {
    id: 13,
    name: 'Kevin White',
    avatar: avatar13,
    friends: [14, 15, 16],
    scores: {
      all: 15000,
      day: 300,
      week: 1200,
      month: 3500,
      year: 15000,
    },
  },
  {
    id: 14,
    name: 'Linda Clark',
    avatar: avatar14,
    friends: [13, 15],
    scores: {
      all: 13000,
      day: 220,
      week: 1050,
      month: 3000,
      year: 13000,
    },
  },
  {
    id: 15,
    name: 'Morgan Turner',
    avatar: avatar15,
    friends: [13, 14, 16],
    scores: {
      all: 14000,
      day: 250,
      week: 1100,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 16,
    name: 'Nathan Adams',
    avatar: avatar16,
    friends: [13, 15],
    scores: {
      all: 11000,
      day: 180,
      week: 900,
      month: 2500,
      year: 11000,
    },
  },
  {
    id: 17,
    name: 'Olivia Hill',
    avatar: avatar17,
    friends: [18, 19, 20],
    scores: {
      all: 20000,
      day: 340,
      week: 1500,
      month: 4200,
      year: 20000,
    },
  },
  {
    id: 18,
    name: 'Paul King',
    avatar: avatar18,
    friends: [17, 19],
    scores: {
      all: 12000,
      day: 200,
      week: 1000,
      month: 2800,
      year: 12000,
    },
  },
  {
    id: 19,
    name: 'Quincy Baker',
    avatar: avatar19,
    friends: [17, 18, 20],
    scores: {
      all: 14000,
      day: 280,
      week: 1150,
      month: 3200,
      year: 14000,
    },
  },
  {
    id: 20,
    name: 'Rachel Scott',
    avatar: avatar20,
    friends: [17, 19],
    scores: {
      all: 10000,
      day: 150,
      week: 800,
      month: 2200,
      year: 10000,
    },
  },
];
