import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ChakraProvider,
  ColorModeScript,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import Navbar from './routes/Navbar';
import Login from './routes/Login';
import Leader from './routes/Leader';
import Game from './routes/Game.jsx';
import Home from './routes/Home';
import DailyChallenge from './routes/DailyChallenge';
import theme from './theme.js';

export const BG_COLOR =
  'linear-gradient(0deg, rgba(2,1,2,1) 0%, rgba(13,1,2,1) 53%, rgba(2,1,2,1) 100%)';

const App = () => {
  const [isSmallerThan300px] = useMediaQuery('(max-width: 300px)');
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Event listener for window resize
  useEffect(() => {
    const handleResize = () => {
      // Calculate the size
      const calHeight = Math.min(
        window.innerHeight * 0.77,
        window.innerWidth * (9 / 16)
      ); // 16:9 aspect ratio
      const calWidth = calHeight * (16 / 9);
      setWindowSize({
        width: calWidth,
        height: calHeight,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Apply background color to the body
  document.body.style.background = BG_COLOR;

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Box
        display="flex"
        justifyContent="center"
        w="100vw"
        maxW={'100vw'}
        fontSize={[`${isSmallerThan300px ? `10px` : `14px`}`, `16px`]}
      >
        <Box
          color="white"
          w="100vw"
          maxW={windowSize.width}
          overflowX={`hidden`}
        >
          <Router>
            <Navbar />
            <Routes>
              <Route
                path="/"
                element={<Home windowWidth={windowSize.width} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/leader" element={<Leader />} />
              <Route path="/game" element={<Game windowSize={windowSize} />} />
              <Route
                path="/dailychallenge"
                element={<DailyChallenge windowSize={windowSize} />}
              />
            </Routes>
          </Router>
        </Box>
      </Box>
      <Box data-testid="window-size" style={{ display: 'none' }}>
        Width: {windowSize.width}, Height: {windowSize.height}
      </Box>
    </ChakraProvider>
  );
};

export default App;
