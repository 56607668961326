import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
} from '@chakra-ui/react';
import logo from '../img/main/Logo.png';
import NewsSticker from '../routes/NewsSticker';

//Use media query to find the width of the screen in pixls

export default function MainPage({ windowWidth }) {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        minHeight="80vh"
        w="100%"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundImage={`url(${logo})`}
          backgroundRepeat="no-repeat"
          backgroundSize={['100% auto', '100% auto', '100% auto', '900px auto']}
          backgroundPosition="center"
        />
        {/* Centered Card with Start Game button */}
        <Card
          position="relative"
          align={'center'}
          maxW="xl"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg="blackAlpha.800"
          color="white"
          boxShadow="dark-lg"
          ml={3}
          mr={3}
        >
          <CardHeader fontSize="2xl" textAlign="center">
            Welcome to Animal Spotr!
          </CardHeader>
          <CardBody>
            <Text>
              AnimalSpotr is an online game where users are presented with a
              60-second video clip of a random animal and must answer 3-4
              questions regarding the animal's phylum, class and order. The
              user's score is calculated based on a multiplier. Their ranking is
              posted on a public leaderboard. Users can share their scores on
              social media and play multiple rounds per day.
            </Text>
          </CardBody>
          <CardFooter textAlign="center">
            <Button colorScheme="blue" onClick={() => navigate('/game')}>
              Start Game
            </Button>
          </CardFooter>
        </Card>
      </Flex>
      <NewsSticker windowWidth={windowWidth} />
    </>
  );
}
