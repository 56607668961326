import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  VStack,
  Heading,
  Avatar,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext.jsx';
import { useNavigate } from 'react-router-dom';
import EmailLogin from '../components/emailLogin';
import {
  isLoggedIn,
  login,
  logout,
  mainUser,
} from '../components/cookiesService';
import EmailRegister from '../components/emailRegister';
import Camera from '../components/camera';
import logoText from '../img/main/LogoText.png';
import SocialLoginComponent from '../components/socialLogin';
import LoginForm from '../components/login';

export default function Login() {
  // State to toggle between login and register
  const [isRegistering, setIsRegistering] = useState(false);
  const { refreshUser } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [curUser, setCurUser] = useState('');
  const [loading, setLoading] = useState(true); // Added loading state
  const [curLoggedOn, setCurLoggedOn] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Await the resolution of isLoggedIn() promise
        const loggedInStatus = await isLoggedIn();
        setCurLoggedOn(loggedInStatus);

        if (loggedInStatus) {
          setCurUser(mainUser().user);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    // Call the async function
    fetchUserData();
  }, []);

  const handleLogin = async values => {
    const loginRes = await EmailLogin(values.email, values.password);
    if (loginRes.res) {
      login(loginRes);
      refreshUser();
      navigate('/');
    } else {
      alert(loginRes.message);
    }
  };

  const handleRegister = async values => {
    const registerRes = await EmailRegister(
      values.email,
      values.password,
      values.username
    );
    if (registerRes.res) {
      login(registerRes);
      refreshUser();
      navigate('/');
    } else {
      alert(registerRes.message);
    }
  };

  const logoutClick = () => {
    logout();
    refreshUser();
    setCurLoggedOn(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="80vh"
    >
      <VStack>
        <Image src={logoText} alt="logo" w={`80%`} maxW={`350px`} />
        <Box>
          {loading ? (
            <Button
              isLoading
              loadingText="Loading"
              colorScheme="gray"
              variant="outline"
              spinnerPlacement="start"
              w="95%"
            ></Button>
          ) : (
            <>
              {curLoggedOn ? (
                <VStack spacing={4} align="center" p={10}>
                  <Avatar
                    size={'2xl'}
                    src={mainUser().avatar}
                    // onClick={() => setIsCameraOn(true)}
                  >
                    <Button
                      size="sm"
                      position="absolute"
                      bottom="0"
                      right="0"
                      transform="translate(50%, 50%)"
                      onClick={() => setIsCameraOn(true)}
                    >
                      <EditIcon />
                    </Button>
                  </Avatar>

                  <Heading as="" size="xl" maxW="110%" noOfLines={1}>
                    {curUser}
                  </Heading>
                  <Heading as="" size="l" noOfLines={2}>
                    Do you want to log out:
                  </Heading>
                  <Button bg={'orange'} onClick={() => logoutClick()}>
                    Logout
                  </Button>
                </VStack>
              ) : (
                <VStack display={`flex`} w="100%" p={5}>
                  <Text textAlign={'center'} fontSize={`2rem`}>
                    {isRegistering ? `Register:` : `Login:`}
                  </Text>
                  <Stack w={`100%`}>
                    <SocialLoginComponent />
                    {isLogin ? (
                      <LoginForm
                        isRegistering={isRegistering}
                        setIsRegistering={value => setIsRegistering(value)}
                        handleLogin={handleLogin}
                        handleRegister={handleRegister}
                      />
                    ) : (
                      <>
                        <Button
                          h={`50px`}
                          fontSize={`1.3rem`}
                          onClick={() => setIsLogin(true)}
                          justifyContent={`flex-start`}
                          bg={'gray.600'}
                          m={`5px`}
                        >
                          📧 Log in with Email
                        </Button>
                        <VStack align={`center`}>
                          <Text>or</Text>

                          <Text
                            cursor={`pointer`}
                            onClick={() => {
                              setIsRegistering(true);
                              setIsLogin(true);
                            }}
                          >
                            Register with Email
                          </Text>
                        </VStack>
                      </>
                    )}
                  </Stack>
                </VStack>
              )}
            </>
          )}

          <Camera
            isCameraOn={isCameraOn}
            setIsCameraOn={value => setIsCameraOn(value)}
          />
        </Box>
      </VStack>
    </Box>
  );
}
