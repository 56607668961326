import React from 'react';
import { Formik, Field, Form } from 'formik';
import {
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import * as Yup from 'yup';

export default function LoginForm({
  isRegistering,
  setIsRegistering,
  handleLogin,
  handleRegister,
}) {
  // Validation schema
  const ValidationSchema = Yup.object().shape({
    ...(isRegistering && {
      username: Yup.string()
        .min(4, 'Username must be at least 4 characters')
        .max(12, 'Username must not exceed 12 characters')
        .required('Username is required'),
    }),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .max(15, 'Password must not exceed 15 characters')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).+$/,
        'Password must include at least one letter, one number, and one special character'
      )
      .required('Password is required'),
  });

  // Handle Login/Register change
  const handleClick = e => {
    setIsRegistering(!isRegistering);
    //   setIsLogin(e);
    //   setCurUser('');
    //   setEmailError(``);
    //   setPassword('');
    //   setRepeatPassword('');
    //   setPasswordError('');
  };

  const handleSubmit = (values, actions) => {
    if (!isRegistering) {
      handleLogin(values);
    } else {
      handleRegister(values);
    }
    setTimeout(() => {
      // actions.resetForm();
      actions.setSubmitting(false);
    }, 1000);
  };

  return (
    <Formik
      initialValues={{
        username: '',
        email: 'test@example.com',
        password: '123456$m',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {props => (
        <Form>
          {isRegistering && (
            <Field name="username">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.username && form.touched.username}
                  isRequired
                >
                  <FormLabel htmlFor="username">Username</FormLabel>
                  <Input
                    {...field}
                    id="username"
                    placeholder="Enter your username"
                  />
                  <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          )}

          <Field name="email">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.email && form.touched.email}
                isRequired
              >
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input {...field} id="email" placeholder="Enter your email" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="password">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.password && form.touched.password}
                isRequired
              >
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  {...field}
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Stack direction="row" spacing={4} mt={4}>
            <Button
              colorScheme="teal"
              isLoading={props.isSubmitting}
              type="submit"
            >
              {isRegistering ? 'Register' : 'Login'}
            </Button>
            <Button variant="outline" onClick={() => handleClick()}>
              Switch to {isRegistering ? 'Login' : 'Register'}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
