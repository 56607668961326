import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  IconButton,
  Flex,
  Divider,
  Textarea,
} from '@chakra-ui/react';
import logo from '../img/main/Logo.png';
import { FiSend } from 'react-icons/fi';

export default function ChatBox({ messages, onSendMessage, windowSize }) {
  const [message, setMessage] = useState('');
  const [textareaHeight, setTextareaHeight] = useState('40px'); // Initial height
  const textareaRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const currentUser = 'Test Man';

  useEffect(() => {
    setTimeout(() => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300); // Adjust delay as needed
  }, [messages]);

  const handleSendMessage = () => {
    if (message.trim()) {
      onSendMessage(message.trim());
      setMessage('');
      setTextareaHeight('40px'); // Reset height after sending
    }
  };

  const handleTextChange = event => {
    if (textareaRef.current.scrollHeight < 200) {
      setTextareaHeight(`calc(${textareaRef.current.scrollHeight}px - 2px )`);
    }
    setMessage(event.target.value);
  };

  const formatDate = timestamp => new Date(timestamp).toLocaleDateString();
  const formatTime = timestamp =>
    new Date(timestamp).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

  let lastDate = null;

  return (
    <>
      <VStack
        spacing={2}
        align="stretch"
        overflowY="auto"
        flex={1}
        p={2}
        pb={4}
        maxH={`calc(${windowSize.windowHeight}px - 115px - ${textareaHeight})`}
        minH={`calc(${windowSize.windowHeight}px - 115px - ${textareaHeight})`}
        w={'100%'}
        position="relative"
        border={'4px solid'}
        borderColor="gray.800"
        borderRadius={'lg'}
        mb={`-20px`}
      >
        <Box
          backgroundImage={`url(${logo})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={0}
        />
        <Box
          bg="blackAlpha.800"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={1}
          borderRadius={'inherit'}
        />
        <VStack
          spacing={2}
          align="stretch"
          overflowY="auto"
          position="relative"
          zIndex={2}
          w="100%"
        >
          {messages.map((msg, index) => {
            const currentDate = formatDate(msg.timestamp);
            let dateSeparator = null;
            if (currentDate !== lastDate) {
              dateSeparator = (
                <Flex alignItems="center" w="100%">
                  <Divider flex="1" borderColor="gray.300" />
                  <Text
                    mx={2}
                    fontWeight={`bold`}
                    fontSize={[`1.1rem`, `1.1rem`, `1.4rem`, `1.4rem`]}
                    color="gray.500"
                  >
                    Daily Challenge {currentDate}
                  </Text>
                  <Divider flex="1" borderColor="gray.300" />
                </Flex>
              );
              lastDate = currentDate;
            }
            return (
              <React.Fragment key={`msg-${index}`}>
                {dateSeparator}
                <HStack
                  justify={
                    msg.username === currentUser ? 'flex-end' : 'flex-start'
                  }
                >
                  <Box
                    bg={msg.username === currentUser ? 'blue.700' : 'gray.800'}
                    p={2}
                    borderRadius="lg"
                    maxW="80%"
                  >
                    <VStack align="stretch" spacing={0}>
                      <HStack>
                        <Text
                          fontSize="xs"
                          color={
                            msg.username === currentUser
                              ? 'orange.200'
                              : 'blue.200'
                          }
                        >
                          {msg.username}:
                        </Text>
                        <Text
                          fontSize="xs"
                          color={
                            msg.username === currentUser
                              ? 'gray.400'
                              : 'gray.600'
                          }
                        >
                          {formatTime(msg.timestamp)}
                        </Text>
                      </HStack>
                      <Text fontSize="sm" color="white">
                        {msg.message}
                      </Text>
                    </VStack>
                  </Box>
                </HStack>
              </React.Fragment>
            );
          })}
          <Box ref={endOfMessagesRef}></Box>
        </VStack>
      </VStack>

      <HStack
        position="sticky"
        bottom={0}
        bg="gray.800"
        w="100%"
        borderRadius={`lg`}
        alignItems={`end`}
        zIndex={4}
        // mt={`-20px`}
      >
        <Textarea
          ref={textareaRef}
          value={message}
          onChange={handleTextChange}
          placeholder="Type your message..."
          resize="none"
          h={textareaHeight}
          minH="40px" // Set minimum height
          maxH="200px" // Set maximum height
          flex={1}
          p={2}
          fontSize="md" // Adjust font size if necessary
          padding="10px" // Adjust padding if necessary
        />
        <IconButton
          icon={<FiSend />}
          colorScheme="blue"
          aria-label="Send message"
          onClick={handleSendMessage}
        />
      </HStack>
    </>
  );
}
