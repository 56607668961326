import { userData } from './userDataBase.jsx';
import { videoDataBase } from './videoDataBase.jsx';
import AnswerBank from './answerBankDB.jsx';
import { leaderBoardData } from './leaderBoardData';

const API_URLS = {
  users: 'users',
  videos: 'videos',
  leaderboard: 'leaderboard',
  answerBank: 'answerbank',
};

const baseUrl = 'false'; // Switch to your REST API URL when ready

export default function apiService(
  endpoint,
  method = 'GET',
  query = null,
  data = null
) {
  // Determine if we are using local data or making an API call
  if (baseUrl === 'false') {
    // Handling local data
    switch (endpoint) {
      //##################### User data #####################
      case API_URLS.users:
        return handleLocalUsersData(method, data);
      // #################### Video data ####################
      case API_URLS.videos:
        if (query && query.id !== undefined) {
          // Find video data that matches id in query from videoDataBase object
          const videoData = videoDataBase.find(video => video.id === query.id);
          return videoData;
        } else {
          return videoDataBase[Math.floor(Math.random() * 4)];
        }
      //################## Leaderboard data #################
      case API_URLS.leaderboard:
        const { userCur, friendsOnly } = query;

        let filteredData = leaderBoardData;

        if (friendsOnly && userCur?.idToken) {
          // Filter for friends only
          const userID = userData.find(user => user.user === userCur.user)?.id;
          if (userID) {
            filteredData = filteredData.filter(user =>
              user.friends.includes(userID)
            );
          }
        }
        return filteredData;

      //################# Answer bank data ################
      case API_URLS.answerBank:
        return AnswerBank(query ? query.type : null);
      default:
        throw new Error('Invalid endpoint');
    }
  } else {
    // ################ Handling API call #################
    const url = `${baseUrl}/${endpoint}`;
    return fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: data ? JSON.stringify(data) : null,
    }).then(response => response.json());

    //######################## End ########################
  }
}

function handleLocalUsersData(method, data) {
  switch (method) {
    case 'GET':
      // Return user matches the idToken in the data
      const user = sessionStorage.getItem('AnimalSpotrUser');
      return JSON.parse(user);
    case 'POST':
      // Find the last user in the userData array and increment the id by 1
      const lastUser = userData[userData.length - 1];
      const id = lastUser ? lastUser.id + 1 : 1;
      // Add the new user to the userData array
      userData.push({ ...data, id });
      return true;
    case 'PUT':
      const userIndex = userData.findIndex(u => u.idToken === data.idToken);
      if (userIndex !== -1) {
        userData[userIndex] = { ...userData[userIndex], ...data };
      }
      break;
    case 'DELETE':
      // Implement logic to delete data from userData
      break;
    default:
      throw new Error('Invalid method for users endpoint');
  }
}
