export const chatMessages = [
  {
    id: 1,
    username: 'Simon',
    message: 'Wow, that was a challenging round!',
    timestamp: '2023-12-30T12:00:00Z',
  },
  {
    id: 2,
    username: 'GameMaster',
    message: 'Glad you enjoyed it!',
    timestamp: '2023-12-30T12:01:00Z',
  },
  {
    id: 3,
    username: 'Sam',
    message: 'I need to improve my score next time.',
    timestamp: '2023-12-30T12:05:00Z',
  },
  {
    id: 4,
    username: 'Nicole',
    message: 'Any tips for getting better?',
    timestamp: '2023-12-30T12:07:00Z',
  },
  {
    id: 5,
    username: 'Micheal',
    message: 'Practice makes perfect!',
    timestamp: '2023-12-30T12:09:00Z',
  },
  {
    id: 6,
    username: 'GameMaster',
    message: 'Try to learn from each round.',
    timestamp: '2023-12-30T12:10:00Z',
  },
  {
    id: 7,
    username: 'George',
    message: 'Just beat my personal best!',
    timestamp: '2023-12-30T12:15:00Z',
  },
  {
    id: 8,
    username: 'Test Man',
    message: 'Congrats Player2!',
    timestamp: '2023-12-30T12:17:00Z',
  },
  {
    id: 9,
    username: 'Micheal',
    message: "It's really competitive today.",
    timestamp: '2023-12-30T12:20:00Z',
  },
  {
    id: 10,
    username: 'GameMaster',
    message: 'Keep up the great work everyone!',
    timestamp: '2023-12-30T12:30:00Z',
  },
  {
    id: 11,
    username: 'Test Man',
    message: 'I love this game!',
    timestamp: '2023-12-30T12:32:00Z',
  },
  {
    id: 12,
    username: 'George',
    message: 'Whoa, that round was intense!',
    timestamp: '2023-12-30T12:34:00Z',
  },
  {
    id: 13,
    username: 'Micheal',
    message: 'Round 2, here I come!',
    timestamp: '2023-12-30T12:36:00Z',
  },
  {
    id: 14,
    username: 'GameMaster',
    message: 'Get ready for the next challenge!',
    timestamp: '2023-12-30T12:38:00Z',
  },
  {
    id: 15,
    username: 'Test Man',
    message: 'Any hints for the next round?',
    timestamp: '2023-12-30T12:40:00Z',
  },
  {
    id: 16,
    username: 'George',
    message: "Can't believe I missed that last question!",
    timestamp: '2023-12-30T12:42:00Z',
  },
  {
    id: 17,
    username: 'Test Man',
    message: 'Focused and ready!',
    timestamp: '2023-12-30T12:44:00Z',
  },
  {
    id: 18,
    username: 'Nicole',
    message: 'This is my favorite part!',
    timestamp: '2023-12-30T12:46:00Z',
  },
  {
    id: 19,
    username: 'Simon',
    message: 'So glad I joined today!',
    timestamp: '2023-12-30T12:48:00Z',
  },
  {
    id: 20,
    username: 'Test Man',
    message: 'And the winner is...',
    timestamp: '2023-12-30T12:50:00Z',
  },
  {
    id: 21,
    username: 'Nicole',
    message: "Let's go for another round!",
    timestamp: '2023-12-30T12:00:00Z',
  },
  {
    id: 22,
    username: 'Simon',
    message: 'That last game was awesome!',
    timestamp: '2023-12-30T12:01:00Z',
  },
  {
    id: 23,
    username: 'George',
    message: 'I almost had it!',
    timestamp: '2023-12-30T12:05:00Z',
  },
  {
    id: 24,
    username: 'Sam',
    message: 'Close call on that last one.',
    timestamp: '2023-12-30T12:07:00Z',
  },
  {
    id: 25,
    username: 'GameMaster',
    message: 'New challenges await.',
    timestamp: '2023-12-30T12:09:00Z',
  },
  {
    id: 26,
    username: 'Test Man',
    message: 'Feeling excited about the next round!',
    timestamp: '2023-12-30T12:10:00Z',
  },
  {
    id: 27,
    username: 'Micheal',
    message: 'Bringing my A-game now.',
    timestamp: '2023-12-30T12:15:00Z',
  },
  {
    id: 28,
    username: 'Nicole',
    message: 'Learning so much from these games.',
    timestamp: '2023-12-30T12:17:00Z',
  },
  {
    id: 29,
    username: 'Simon',
    message: 'What a learning experience!',
    timestamp: '2023-12-30T12:20:00Z',
  },
  {
    id: 30,
    username: 'GameMaster',
    message: 'Keep it up, everyone!',
    timestamp: '2023-12-30T12:30:00Z',
  },
  {
    id: 31,
    username: 'Sam',
    message: 'Here comes my comeback.',
    timestamp: '2023-12-30T12:32:00Z',
  },
  {
    id: 32,
    username: 'George',
    message: 'Ready for more!',
    timestamp: '2024-01-01T12:34:00Z',
  },
  {
    id: 33,
    username: 'Test Man',
    message: 'Happy New Year, gamers!',
    timestamp: '2024-01-01T12:36:00Z',
  },
  {
    id: 34,
    username: 'Micheal',
    message: 'Starting the year on a high note!',
    timestamp: '2024-01-01T12:38:00Z',
  },
  {
    id: 35,
    username: 'Nicole',
    message: 'New year, new challenges!',
    timestamp: '2024-01-01T12:40:00Z',
  },
  {
    id: 36,
    username: 'Simon',
    message: `Let's make this year count!`,
    timestamp: '2024-01-01T12:42:00Z',
  },
  {
    id: 37,
    username: 'GameMaster',
    message: '2024 is going to be epic!',
    timestamp: '2024-01-01T12:44:00Z',
  },
  {
    id: 38,
    username: 'Sam',
    message: 'Ready to take on 2024!',
    timestamp: '2024-01-01T12:46:00Z',
  },
  {
    id: 39,
    username: 'George',
    message: 'Aiming for the top this year!',
    timestamp: '2024-01-01T12:48:00Z',
  },
  {
    id: 40,
    username: 'Test Man',
    message: `Let's all do our best!`,
    timestamp: '2024-01-01T12:50:00Z',
  },

  {
    id: 41,
    username: 'GameMaster',
    message: 'New challenges for the new year!',
    timestamp: '2024-01-02T12:09:00Z',
  },
  {
    id: 42,
    username: 'Test Man',
    message: 'Excited to start the year strong!',
    timestamp: '2024-01-02T12:10:00Z',
  },
  {
    id: 43,
    username: 'Micheal',
    message: 'Bringing my A-game in 2024!',
    timestamp: '2024-01-02T12:15:00Z',
  },
  {
    id: 44,
    username: 'Nicole',
    message: 'Continuing to learn and grow!',
    timestamp: '2024-01-02T12:17:00Z',
  },
  {
    id: 45,
    username: 'Simon',
    message: 'Ready for more challenges!',
    timestamp: '2024-01-02T12:20:00Z',
  },
  {
    id: 46,
    username: 'GameMaster',
    message: 'Keep pushing forward!',
    timestamp: '2024-01-02T12:30:00Z',
  },
  {
    id: 47,
    username: 'Sam',
    message: 'Here comes another comeback!',
    timestamp: '2024-01-02T12:32:00Z',
  },
  {
    id: 48,
    username: 'George',
    message: 'Ready for the new challenges!',
    timestamp: '2024-01-02T12:34:00Z',
  },
  {
    id: 49,
    username: 'Test Man',
    message: 'Happy New Year, gamers!',
    timestamp: '2024-01-02T12:36:00Z',
  },
  {
    id: 50,
    username: 'Micheal',
    message: 'Starting the year with enthusiasm!',
    timestamp: '2024-01-02T12:38:00Z',
  },
  {
    id: 51,
    username: 'Nicole',
    message: 'Embracing new opportunities!',
    timestamp: '2024-01-02T12:40:00Z',
  },
  {
    id: 52,
    username: 'Simon',
    message: `Let's make this year count!`,
    timestamp: '2024-01-02T12:42:00Z',
  },
  {
    id: 53,
    username: 'GameMaster',
    message: '2024 is going to be epic!',
    timestamp: '2024-01-02T12:44:00Z',
  },
  {
    id: 54,
    username: 'Sam',
    message: 'Ready to take on 2024!',
    timestamp: '2024-01-02T12:46:00Z',
  },
  {
    id: 55,
    username: 'George',
    message: 'Aiming for the top this year!',
    timestamp: '2024-01-02T12:48:00Z',
  },
  {
    id: 56,
    username: 'Test Man',
    message: `Let's all do our best!`,
    timestamp: '2024-01-02T12:50:00Z',
  },
  {
    id: 57,
    username: 'GameMaster',
    message: 'New year, new challenges!',
    timestamp: '2024-01-03T12:09:00Z',
  },
  {
    id: 58,
    username: 'Test Man',
    message: 'Excited for the next round!',
    timestamp: '2024-01-03T12:10:00Z',
  },
  {
    id: 59,
    username: 'Micheal',
    message: 'Bringing my A-game today!',
    timestamp: '2024-01-03T12:15:00Z',
  },
  {
    id: 60,
    username: 'Nicole',
    message: 'Learning and growing every day!',
    timestamp: '2024-01-03T12:17:00Z',
  },
  {
    id: 61,
    username: 'Simon',
    message: 'What a learning experience!',
    timestamp: '2024-01-03T12:20:00Z',
  },
  {
    id: 62,
    username: 'GameMaster',
    message: 'Keep it up, everyone!',
    timestamp: '2024-01-03T12:30:00Z',
  },
  {
    id: 63,
    username: 'Sam',
    message: 'Here comes my comeback.',
    timestamp: '2024-01-03T12:32:00Z',
  },
  {
    id: 64,
    username: 'George',
    message: 'Ready for more!',
    timestamp: '2024-01-03T12:34:00Z',
  },
  {
    id: 65,
    username: 'Test Man',
    message: 'Happy New Year, gamers!',
    timestamp: '2024-01-03T12:36:00Z',
  },
  {
    id: 66,
    username: 'Micheal',
    message: 'Starting the year on a high note!',
    timestamp: '2024-01-03T12:38:00Z',
  },
  {
    id: 67,
    username: 'Nicole',
    message: 'New year, new challenges!',
    timestamp: '2024-01-03T12:40:00Z',
  },
  {
    id: 68,
    username: 'Simon',
    message: `Let's make this year count!`,
    timestamp: '2024-01-03T12:42:00Z',
  },
  {
    id: 69,
    username: 'GameMaster',
    message: '2024 is going to be epic!',
    timestamp: '2024-01-03T12:44:00Z',
  },
  {
    id: 70,
    username: 'Sam',
    message: 'Ready to take on 2024!',
    timestamp: '2024-01-03T12:46:00Z',
  },
  {
    id: 71,
    username: 'George',
    message: 'Aiming for the top this year!',
    timestamp: '2024-01-03T12:48:00Z',
  },
  {
    id: 72,
    username: 'Test Man',
    message: `Let's all do our best!`,
    timestamp: '2024-01-03T12:50:00Z',
  },
];
