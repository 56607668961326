import Cookies from 'universal-cookie';
import apiService from '../localServer/apiService';

const fetchAnswers = async type => {
  try {
    const response = await apiService('answerbank', `GET`, { type });
    return response;
  } catch (err) {
    console.error('Error fetching answers:', err);
  }
};

const answersBank = await fetchAnswers('animals');

// Generate a random guest ID choosing one of the answers from the answer bank type animals
const randomGuestID = `guest_${
  answersBank[Math.floor(Math.random() * answersBank.length)].substring(0, 7) // Limit the selected word to 7 characters
}`.substring(0, 11); // Ensure the whole ID is no more than 14 characters

const defaultUser = {
  user: randomGuestID,
  idToken: null,
  avatar: null,
  dailyChallenge: {
    complete: false,
    score: [],
    round: 0,
  },
  scores: {
    all: 0,
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  },
};

const cookies = new Cookies();
const storedUserData = sessionStorage.getItem('AnimalSpotrUser');

let userLoggedIn;
if (storedUserData && storedUserData !== 'undefined') {
  // Parse the stored data only if it's valid
  userLoggedIn = JSON.parse(storedUserData);
} else {
  // Use default user if nothing valid is stored
  userLoggedIn = { ...defaultUser };
}

export const isLoggedIn = async () => {
  let userIDToken = cookies.get('AnimalSpotr')?.idToken;
  if (userIDToken) {
    try {
      const response = await apiService('users', 'GET', null, {
        idToken: userIDToken,
      });
      sessionStorage.setItem('AnimalSpotrUser', JSON.stringify(response));
      return response;
    } catch (err) {
      console.error('Error fetching user:', err);
      return false;
    }
  } else return false;
};

export const login = data => {
  cookies.set(
    'AnimalSpotr',
    { user: data.user, idToken: data.idToken },
    { path: '/' }
  );
  sessionStorage.setItem('AnimalSpotrUser', JSON.stringify(data));
  userLoggedIn = data;
};

export const logout = () => {
  cookies.remove('AnimalSpotr', { path: '/' });
  sessionStorage.removeItem('AnimalSpotrUser');
  userLoggedIn = { ...defaultUser };
};

export const mainUser = () => {
  return userLoggedIn;
};

export const setAvatar = avatar => {
  userLoggedIn = { ...userLoggedIn, avatar: avatar };
  sessionStorage.setItem('AnimalSpotrUser', JSON.stringify(userLoggedIn));

  // Update the user data through an API call
  apiService('users', 'PUT', null, {
    user: userLoggedIn.user,
    avatar: avatar,
  });
};

export const updateScores = score => {
  userLoggedIn = {
    ...userLoggedIn,
    scores: {
      ...userLoggedIn.scores,
      all: userLoggedIn.scores.all + score,
      day: userLoggedIn.scores.day + score,
      week: userLoggedIn.scores.week + score,
      month: userLoggedIn.scores.month + score,
      year: userLoggedIn.scores.year + score,
    },
  };
  sessionStorage.setItem('AnimalSpotrUser', JSON.stringify(userLoggedIn));
};

export const updateDailyChallenge = (updates = {}) => {
  const { score, round, complete } = updates;

  const updatedDailyChallenge = {
    ...userLoggedIn.dailyChallenge,
    ...(score !== undefined && { score }),
    ...(round !== undefined && { round }),
    ...(complete !== undefined && { complete }),
  };

  userLoggedIn = {
    ...userLoggedIn,
    dailyChallenge: updatedDailyChallenge,
  };

  sessionStorage.setItem('AnimalSpotrUser', JSON.stringify(userLoggedIn));
};
