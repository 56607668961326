import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import logo from '../img/main/Logo.png';
import apiService from '../localServer/apiService';
import { useAuth } from '../context/AuthContext';
import { updateDailyChallenge } from './cookiesService';

export default function VideoComponent({
  url,
  maxPauses,
  isPlaying,
  setIsPlaying,
  restart,
  focusInput,
  activeQuestion,
  windowSize,
  user,
  rounds,
}) {
  const [pauseCount, setPauseCount] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null); // url[activeQuestion - 1
  const playerRef = useRef(null);
  const { refreshUser } = useAuth();

  const handlePlay = () => {
    // user.dailyChallenge.round += 1;
    setIsPlaying(true);
    setIsPaused(false);
    setTimeout(() => {
      focusInput();
    }, 100);
    // set the daily challenge round to the next round apiService
    apiService('users', 'PUT', null, {
      idToken: user.idToken,
      dailyChallenge: { round: user.dailyChallenge.round + 1 },
    });
    updateDailyChallenge({ round: user.dailyChallenge.round + 1 });
    refreshUser();
  };

  useEffect(() => {
    //St the video to the beggining when the game restarts
    setIsPaused(true);
    setPauseCount(0);
    if (playerRef.current && playerRef.current.seekTo) {
      playerRef.current.seekTo(0);
    }
  }, [restart]);

  // change the video when the active question changes
  useEffect(() => {
    setVideoUrl(url[activeQuestion - 1]);
  }, [activeQuestion, url]);

  //if game is finished, pause the video
  useEffect(() => {
    if (isPlaying === false) {
      setIsPaused(true);
    }
  }, [isPlaying]);

  // Toggle mute/unmute
  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handlePause = () => {
    // If the pause count is less than the max pauses allowed, increment the pause count
    // also set the playing status to false
    if (pauseCount !== maxPauses) {
      setPauseCount(prevPauseCount => {
        if (prevPauseCount < maxPauses) {
          setIsPaused(true);
          setIsPlaying(false);
          return prevPauseCount + 1;
        } else {
          setIsPaused(false);
          setIsPlaying(true);
          return prevPauseCount;
        }
      });
    }
  };
  return (
    <Box pos={`relative`} zIndex={3}>
      <Box
        pos="absolute"
        top={0}
        left={'50%'}
        transform={`translateX(-50%)`}
        w={`100%`}
        h={`100%`}
        display={`flex`}
        bg="black"
        backgroundImage={`url(${logo})`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        zIndex={-1}
      >
        <Box
          pos="absolute"
          top={0}
          left={0}
          w={`100%`}
          h={`100%`}
          display={`flex`}
          justifyContent={`center`}
          alignItems={`center`}
          flexDirection={`column`}
          bg="blackAlpha.800"
        >
          <Button isLoading loadingText="Loading" variant={`outline`}>
            {`Loading`}
          </Button>
        </Box>
      </Box>
      <ReactPlayer
        ref={playerRef}
        onEnded={() => setVideoUrl(url[0])}
        style={{ margin: '0 auto' }}
        url={videoUrl}
        playsinline={true}
        height={`${`100%`}px`}
        width={`${`100%`}px`}
        muted={isMuted}
        controls={false}
        playing={!isPaused}
        onClick={handlePause}
        onReady={() => setBtnDisabled(false)}
      />

      {isPaused && (
        <Box
          pos="absolute"
          top={0}
          left={'50%'}
          transform={`translateX(-50%)`}
          w={`100%`}
          h={`100%`}
          display={`flex`}
          bg="black"
          backgroundImage={`url(${logo})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          zIndex={2}
        >
          <Box
            pos="absolute"
            top={0}
            left={0}
            w={`100%`}
            h={`100%`}
            display={`flex`}
            justifyContent={`center`}
            alignItems={`center`}
            flexDirection={`column`}
            bg="blackAlpha.800"
          >
            <Button
              bg={'green'}
              m={5}
              onClick={handlePlay}
              isLoading={btnDisabled}
            >
              {pauseCount === 0 ? 'Start' : 'Resume'}
            </Button>
            <Text
              fontSize={[
                `4.5vw`,
                `4.5vw`,
                `1.5rem`,
                `1.5rem`,
                `1.5rem`,
                `2.5rem`,
              ]}
            >
              {maxPauses > 0
                ? `You can pause the video ${maxPauses - pauseCount} more ${
                    maxPauses - pauseCount === 1 ? 'time' : 'times'
                  }.`
                : `You can't pause the video once started.`}
            </Text>
          </Box>
        </Box>
      )}
      <Box
        // aria-label={isMuted ? 'Unmute' : 'Mute'}
        onClick={toggleMute}
        pos={'absolute'}
        bottom={'10px'}
        right={'10px'}
        zIndex={5}
        bg="blackAlpha.200"
        borderRadius={'full'}
        p={1}
        fontSize={'2rem'}
      >
        {!isPlaying ? `` : isMuted ? '🔇' : '🔊'}{' '}
        {/* Place emojis as children */}
      </Box>
    </Box>
  );
}
