import React, { useEffect, useRef, useState } from 'react';
import { keyframes } from '@emotion/react';
import {
  Input,
  Box,
  UnorderedList,
  ListItem,
  Button,
  HStack,
  VStack,
  Flex,
  Text,
} from '@chakra-ui/react';

import wrongBuzz from '../img/sounds/wrongBuzz.mp3';
import apiService from '../localServer/apiService';

const shakeAnimation = keyframes`
0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
`;

export default function Questions({
  type = `animals`,
  isPlaying,
  gameData,
  activeQuestion,
  setActiveQuestion,
  numQuestions,
  setEndRound,
  inputRef,
  user,
  rounds,
  focusInput,
  handleBlur,
}) {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isShaking, setIsShaking] = useState(false);
  const answers = useRef([]);

  //get the answers from the apiService / Database
  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await apiService('answerbank', `GET`, { type });
        answers.current = response;
      } catch (err) {
        console.error('Error fetching answers:', err);
      }
    };

    fetchAnswers();
  }, [type]);

  useEffect(() => {
    // Clear suggestions when new question is active
    setInputValue('');
    setSuggestions([]);
  }, [activeQuestion]);

  const handleInputChange = event => {
    const value = event.target.value;
    setInputValue(value);

    // ############### Filter the answers for suggestions #################
    if (value.length > 0) {
      // Filter the answers for suggestions that start with the input value
      const filteredSuggestions = answers.current.filter(answer =>
        answer.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // ################ handleSuggestionClick ########################
  const handleSuggestionClick = suggestion => {
    focusInput();
    setInputValue(suggestion);
    setSuggestions([]);
  };

  const handleAnswerSubmit = () => {
    if (inputValue === gameData[activeQuestion].answer) {
      gameData[activeQuestion].complete = true;
      gameData[activeQuestion].correct = true;
      if (numQuestions > parseInt(activeQuestion)) {
        setActiveQuestion((parseInt(activeQuestion) + 1).toString());
      } else {
        setSuggestions([]);
        handleBlur();
        setInputValue('');
        setEndRound(true);
      }
      setInputValue('');
      setSuggestions([]);
      focusInput();
    } else {
      gameData[activeQuestion].trys += 1;
      setIsShaking(true);
      const audio = new Audio(wrongBuzz);
      audio.play();
      focusInput();
      setSuggestions([]);
      setTimeout(() => {
        setIsShaking(false);
        setInputValue('');
      }, 1000);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleAnswerSubmit();
    }
  };
  const clearBtn = () => {
    setInputValue('');
    focusInput();
  };

  return (
    <Box
      fontSize={[`4.5vw`, `0.9rem`, `1.2rem`, `1.2rem`, `1.2rem`, `1.2rem`]}
      bg={'black'}
      zIndex={2}
    >
      <VStack>
        {/*############## Question box ########### */}
        <Box pt={2}>
          {isPlaying || gameData[activeQuestion]?.complete
            ? `Question ${activeQuestion}: ${gameData[activeQuestion]?.question}`
            : user.dailyChallenge.complete
            ? `Round 1 of 1`
            : `Daily Challenge - Round ${
                user.dailyChallenge.round + 1
              } of ${rounds}`}
        </Box>
        {/*############## Answer box ########### */}
        <HStack spacing={2} w={`100%`} pl={1} pr={1}>
          <Input
            // position="sticky"
            // bottom="0"
            isDisabled={!isPlaying || gameData[activeQuestion]?.complete}
            ref={inputRef}
            onFocus={focusInput}
            onBlur={handleBlur}
            type="text"
            bg={'gray.900'}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Type your answer..."
            color={isShaking ? 'red.500' : 'white'}
            sx={{
              animation: isShaking
                ? `${shakeAnimation} 0.3s infinite`
                : undefined,
            }}
          />
          {/*############## Submit Button ########### */}
          <Button
            isDisabled={!isPlaying || gameData[activeQuestion]?.complete}
            onClick={() => {
              handleAnswerSubmit();
            }}
            w={['80px', '150px']}
            borderLeftRadius={20}
            bg={'green.500'}
            _hover={{ bg: 'green.700' }}
            fontSize={['xs', 'md']}
          >
            Submit
          </Button>
          {/*############## Clear Button ########### */}
          <Button
            onClick={() => clearBtn()}
            w={['80px', '150px']}
            bg={'red.500'}
            _hover={{ bg: 'red.700' }}
            isDisabled={!isPlaying || gameData[activeQuestion]?.complete}
            fontSize={['xs', 'md']}
          >
            Clear
          </Button>
        </HStack>
      </VStack>
      {/*############## Suggestions Answers ########### */}
      <Box>
        {suggestions.length > 0 && (
          <UnorderedList mt={2} p={1} listStyleType="none">
            <Flex wrap="wrap">
              {suggestions.map((suggestion, index) => (
                <React.Fragment key={index}>
                  {/* Render the suggestion */}
                  <ListItem
                    cursor="pointer"
                    mb={2}
                    px={2}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <Text as="span">{suggestion}</Text>
                  </ListItem>
                  {/* Render a dash after each suggestion except the last one */}
                  {index < suggestions.length - 1 && (
                    <Text as="span" mx={1}>
                      -
                    </Text>
                  )}
                </React.Fragment>
              ))}
            </Flex>
          </UnorderedList>
        )}
      </Box>
    </Box>
  );
}
