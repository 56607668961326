import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useMediaQuery,
  Image,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { updateScores } from './cookiesService';
import { useAuth } from '../context/AuthContext';

export default function EndRound({
  isOpen,
  onClose,
  gameData,
  stats,
  scorePerAns,
  numQuestions,
  bonus,
  user,
  rounds,
  image,
  name,
  windowSize,
}) {
  const { refreshUser } = useAuth();
  const [isSmallerThan300px] = useMediaQuery('(max-width: 300px)');

  const fontSize = isSmallerThan300px ? 'xs' : 'md';

  const score = () => {
    let score = 0;
    let isbonus = 0;
    Object.keys(gameData).forEach(key => {
      if (gameData[key].correct) {
        score +=
          gameData[key].trys >= 6 ? 5 : scorePerAns - 5 * gameData[key].trys;
        isbonus += 1;
      }
    });

    if (isbonus === numQuestions) {
      score += bonus;
    }
    return score;
  };

  const onGameComplete = () => {
    updateScores(score());
    refreshUser();
    if (!user.dailyChallenge.complete) {
      user.dailyChallenge.score.push([
        score(),
        scorePerAns * numQuestions + bonus,
      ]);
    }

    onClose();
  };

  //   const totalScore = gameData.reduce((acc, item) => acc + item.score, 0);

  return (
    <Modal isOpen={isOpen} onClose={onGameComplete} isCentered>
      <ModalOverlay />
      <ModalContent
        mx={5}
        px={2}
        maxW="800px"
        bg="gray.700"
        maxH={windowSize.windowHeight * 0.98}
      >
        <ModalHeader pb={0}>
          {user.dailyChallenge.complete
            ? `Summery`
            : `Round ${user.dailyChallenge.round}/${rounds} Summary`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          {/*Add image to modal*/}
          <Image
            src={image}
            alt="game summary"
            w={'100%'}
            h={'auto'}
            borderRadius={5}
            pb={0}
          />
          {/* Add animal name */}
          <Text fontSize="xl" fontWeight="bold" mt={1} mb={2}>
            {name}
          </Text>
          <UnorderedList ml={4} pb={2}>
            {Object.entries(gameData).map(([key, data], index) => (
              <ListItem key={key}>
                {data.questionType} - {data.answer}
              </ListItem>
            ))}
          </UnorderedList>

          <Table variant="striped" colorScheme="" size={['sm', `xl`]}>
            <Thead>
              <Tr>
                <Th>Stat</Th>
                <Th>Value</Th>
              </Tr>
            </Thead>
            <Tbody mb={0}>
              <Tr>
                <Td>Score</Td>
                <Td>{`${score()} out of ${
                  scorePerAns * numQuestions + bonus
                }`}</Td>
              </Tr>

              {Object.entries(stats).map(([key, value]) => (
                <Tr key={key}>
                  {key === 'funFact' ? (
                    <Td colSpan="2" pt={[2, 2, 5]} pb={[2, 2, 5]}>
                      {value}
                    </Td>
                  ) : (
                    <>
                      <Td>{value[0]}</Td>
                      <Td>{value[1]}</Td>
                    </>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <Button
            mt={0}
            colorScheme="green"
            mr={3}
            fontSize={fontSize}
            onClick={() => onGameComplete()}
          >
            {user.dailyChallenge.complete
              ? `Next Game`
              : user.dailyChallenge.round >= rounds
              ? `Game Summary`
              : `Next Round`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
