import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  ButtonGroup,
  Button,
  Avatar,
  useMediaQuery,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { mainUser } from '../components/cookiesService';
// import { userData } from '../localServer/userDataBase';
import apiService from '../localServer/apiService';

// Score selector array with test and handleTogle display
const scoreSelector = [
  { label: 'All Time', value: 'all' },
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

const Leader = () => {
  const [filteredLeaderboard, setFilteredLeaderboard] = useState([]);
  const [displayFriends, setDisplayFriends] = useState(false);
  const [timeFrame, setTimeFrame] = useState('all'); // 'all', 'day', 'week', 'month', 'year'
  const navigate = useNavigate();
  const [isSmallerThan300px] = useMediaQuery('(max-width: 300px)');

  useEffect(() => {
    const userCur = mainUser();
    if (!userCur.idToken && displayFriends) navigate('/login');
    else {
      //################ Handeling Leaderboard api call ###################
      const query = {
        userCur: userCur,
        friendsOnly: displayFriends,
      };

      const fetchLeaderboard = async () => {
        try {
          const response = await apiService('leaderboard', 'GET', query);
          // Further filter based on the selected time frame
          if (response) {
            const filteredByTimeFrame = response.map(user => {
              const scores = user.scores[timeFrame];
              return { ...user, score: scores ? scores : user.score };
            });

            // Sort the leaderboard based on the score
            const sortedLeaderboard = filteredByTimeFrame.sort(
              (a, b) => b.score - a.score
            );

            setFilteredLeaderboard(sortedLeaderboard);
          }
        } catch (err) {
          console.error('Error fetching leaderboard:', err);
        }
      };

      fetchLeaderboard();
    }
  }, [displayFriends, timeFrame, navigate]);

  const handleToggleDisplay = mode => {
    setDisplayFriends(mode === 'friends');
  };

  const handleToggleTimeFrame = frame => {
    setTimeFrame(frame);
  };

  return (
    <Box p={2}>
      <h2>Leaderboard</h2>
      <ButtonGroup variant="outline" w={`100%`} mb={4} justifyContent="center">
        <Button
          fontSize={isSmallerThan300px ? 'xs' : 'auto'}
          onClick={() => handleToggleDisplay('all')}
          isActive={!displayFriends}
        >
          Show All Users
        </Button>
        <Button
          mr={2}
          fontSize={isSmallerThan300px ? 'xs' : 'auto'}
          onClick={() => handleToggleDisplay('friends')}
          isActive={displayFriends}
        >
          Show Friends Only
        </Button>
      </ButtonGroup>
      <ButtonGroup
        variant="outline"
        mb={3}
        w={`100%`}
        justifyContent={'center'}
      >
        {scoreSelector.map(({ label, value }) => (
          <Button
            key={value}
            p={isSmallerThan300px ? 1 : 2}
            fontSize={isSmallerThan300px ? 'xs' : 'auto'}
            onClick={() => handleToggleTimeFrame(value)}
            isActive={timeFrame === value}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
      <Table
        variant="striped"
        size={['xm', 'sm', 'md']}
        maxW={`95%`}
        align="center"
      >
        <Thead>
          <Tr>
            <Th>Rank</Th>
            <Th>Avatar</Th>
            <Th>Name</Th>
            <Th>Score</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredLeaderboard.map((entry, index) => (
            <Tr key={entry.id}>
              <Td>{index + 1}</Td>
              <Td>
                <Avatar
                  size={['xs', 'sm', 'md']}
                  src={entry.avatar}
                  bg="teal.500"
                  name={entry.name}
                />
              </Td>
              <Td>{entry.name}</Td>
              <Td>{entry.score}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Leader;
