import React, { useCallback, useState } from 'react';
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  //   LoginSocialMicrosoft,
  //   LoginSocialApple,
  //   IResolveParams,
} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
  //   MicrosoftLoginButton,
  //   AppleLoginButton,
} from 'react-social-login-buttons';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { login } from './cookiesService';
import { useAuth } from '../context/AuthContext';
import apiService from '../localServer/apiService';

const SocialLoginComponent = () => {
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();
  const navigate = useNavigate();
  const { refreshUser } = useAuth();

  const responseMessage = async (provider, data) => {
    const userData = {
      idToken: data?.userID || data?.id || data?.idToken || data?.sub,
      friends: [2, 3, 4, 6, 8, 9, 15, 17, 18, 19],
      dailyChallenge: {
        complete: false,
        score: [],
        round: 0,
      },
      scores: {
        all: 234555,
        day: 7000,
        week: 11200,
        month: 93500,
        year: 115000,
      },
    };
    userData.avatar =
      data?.avatar ||
      data?.profile_image_url ||
      data?.avatar_url ||
      data?.picture?.data?.url ||
      data?.picture ||
      data?.profile_image_url_https ||
      'https://maxcdn.icons8.com/Share/icon/p1em/users//gender_neutral_user1600.png';

    userData.user = data?.short_name || data?.given_name;
    // Call put to api and then await  for apiService to finish then login

    try {
      // Await the resolution of the apiService() promise
      const loggedInStatus = await apiService('users', 'POST', null, userData);

      // Assuming loggedInStatus is a boolean indicating success
      if (loggedInStatus) {
        login(userData);
        refreshUser();
        navigate('/');
      } else {
        // Handle failed login
        console.log('Login failed');
      }
    } catch (error) {
      console.error(error);
    }

    // alert(
    //   `Logged in with ${provider} successfully. \n\n Access token to be sent to server \n\n ${
    //     provider === `facebook` ? data.accessToken : data.access_token
    //   }`
    // );
  };

  const onLoginStart = useCallback(() => {
    // alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    // alert('logout success');
  }, []);

  return (
    <>
      {provider && profile && <Button onClick={onLogoutSuccess} />}

      <LoginSocialGoogle
        // isOnlyGetToken
        client_id={`1072891631951-llfbovgk07p81adnaap6i9mmrl3nrjp6.apps.googleusercontent.com`}
        onLoginStart={onLoginStart}
        onResolve={({ provider, data }) => {
          responseMessage(provider, data);
          setProvider(provider);
          setProfile(data);
        }}
        onReject={err => {
          console.log(err);
        }}
      >
        <GoogleLoginButton />
      </LoginSocialGoogle>

      <LoginSocialFacebook
        // isOnlyGetToken
        appId={'870997058003566'}
        onLoginStart={onLoginStart}
        version="v18.0"
        scope="public_profile"
        onResolve={({ provider, data }) => {
          responseMessage(provider, data);
          setProvider(provider);
          setProfile(data);
        }}
        onReject={err => {
          console.log(err);
        }}
      >
        <FacebookLoginButton />
      </LoginSocialFacebook>

      {/* <LoginSocialApple
          client_id={process.env.REACT_APP_APPLE_ID || ''}
          scope={'name email'}
          redirect_uri={REDIRECT_URI}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <AppleLoginButton />
        </LoginSocialApple>

        <LoginSocialMicrosoft
          client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
          redirect_uri={REDIRECT_URI}
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            setProvider(provider);
            setProfile(data);
          }}
          onReject={(err: any) => {
            console.log(err);
          }}
        >
          <MicrosoftLoginButton />
        </LoginSocialMicrosoft> */}
    </>
  );
};

export default SocialLoginComponent;
